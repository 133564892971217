@font-face {
	font-family: 'Avenir Next';
	src: local('☺︎'), url('/_n/fonts/AvenirNext-Light.woff2') format('woff2'),
		url('/_n/fonts/AvenirNext-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Avenir Next';
	src: local('☺︎'), url('/_n/fonts/AvenirNext-LightItalic.woff2') format('woff2'),
		url('/_n/fonts/AvenirNext-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Avenir Next';
	src: local('☺︎'), url('/_n/fonts/AvenirNext-Regular.woff2') format('woff2'),
		url('/_n/fonts/AvenirNext-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Avenir Next';
	src: local('☺︎'), url('/_n/fonts/AvenirNext-RegularItalic.woff2') format('woff2'),
		url('/_n/fonts/AvenirNext-RegularItalic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Avenir Next';
	src: local('☺︎'), url('/_n/fonts/AvenirNext-Medium.woff2') format('woff2'),
		url('/_n/fonts/AvenirNext-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Avenir Next';
	src: local('☺︎'), url('/_n/fonts/AvenirNext-MediumItalic.woff2') format('woff2'),
		url('/_n/fonts/AvenirNext-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Avenir Next';
	src: local('☺︎'), url('/_n/fonts/AvenirNext-Demi.woff2') format('woff2'),
		url('/_n/fonts/AvenirNext-Demi.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Avenir Next';
	src: local('☺︎'), url('/_n/fonts/AvenirNext-DemiItalic.woff2') format('woff2'),
		url('/_n/fonts/AvenirNext-DemiItalic.woff') format('woff');
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Century';
	src: url('/_n/fonts/Century-Regular.woff2') format('woff2'),
		url('/_n/fonts/Century-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Century';
	src: url('/_n/fonts/Century-Italic.woff2') format('woff2'),
		url('/_n/fonts/Century-Italic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'ITC Souvenir';
	src: url('/_n/fonts/ITCSouvenir-Regular.woff2') format('woff2'),
		url('/_n/fonts/ITCSouvenir-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Neue Plak';
	src: url('/_n/fonts/NeuePlak-WideBlack.woff2') format('woff2'),
		url('/_n/fonts/NeuePlak-WideBlack.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}
